import styled from 'styled-components';

import media from '../../shared/media';

export const Wrapper = styled.header`
  padding-top: 80px;
  padding-bottom: 40px;

  ${media.tablet} {
    padding-top: 100px;
  }

  ${media.desktop} {
    padding-top: 120px;
  }
`;

export const Title = styled.h2`
  font-family: 'Noto Serif';
  font-size: 28px;
  line-height: 36px;
  font-weight: 500;
  letter-spacing: 0.5px;
  margin: 0px;
  color: ${({theme}) => theme.colors.textColor};
  transition: color 0.2s;
  text-align: left;

  ${media.tablet} {
    font-size: 40px;
    line-height: 48px;
    padding: 0 20px;
  }

  ${media.desktop} {
    padding: 0;
  }
`;

export const Subtitle = styled.div`
  margin: 0;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  color: ${({theme}) => theme.colors.textColor};
  transition: color 0.2s;
  text-align: left;

  ${media.tablet} {
    font-size: 24px;
    line-height: 32px;
    padding: 0 20px;
  }

  ${media.desktop} {
    margin-bottom: 40px;
    padding: 0;
  }

  .section-with-image & {
    color: #fff;
  }

  p {
    margin: 0;
  }

  &:before {
    content: '';
    display: inline-block;
    width: 20%;
    height: 3px;
    background-color: rgba(120, 194, 87, 0.5);
  }
`;
