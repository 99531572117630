import React, {useMemo} from 'react';
import PropTypes from 'prop-types';

import Container from '../Container';
import * as Styled from './Hero.styles';
import {graphql} from 'gatsby';

const Hero = ({children, backgroundImage, pageContext}) => {
  const alignment = useMemo(
    () =>
      backgroundImage || pageContext?.slug.includes('insights/')
        ? 'left'
        : 'center',
    [backgroundImage]
  );

  return (
    <Styled.Wrapper
      className={[`hero-alignment-${alignment}`].join(' ')}
      isCaseStudy={pageContext?.slug.includes('case-studies')}
    >
      <Styled.ContentWrapper>
        <Container pageContext={pageContext}>{children}</Container>
      </Styled.ContentWrapper>
      {backgroundImage && (
        <Styled.Image>
          <img src={backgroundImage.file.url} alt="background" loading="lazy" width="865" height="800"/>
        </Styled.Image>
      )}
    </Styled.Wrapper>
  );
};

export const query = graphql`
  fragment Hero on ContentfulHero {
    title {
      childMarkdownRemark {
        html
      }
    }
    enableShareBlock
    content {
      childMarkdownRemark {
        html
      }
    }
    backgroundImage {
      file {
        url
      }
    }
    image {
      file {
        url
      }
    }
    createdAt
  }
`;

Hero.Title = Styled.Title;
Hero.Content = Styled.Content;
Hero.Image = Styled.ContentImage;

Hero.propTypes = {
  title: PropTypes.string.isRequired,
};

export default Hero;
