import styled from 'styled-components';

import media from '../../shared/media';

export const Wrapper = styled.header``;

export const Subtitle = styled.div`
  font-size: 18px;
  font-weight: 600;
  line-height: 18px;
  text-transform: uppercase;
  margin-top: 16px;
  color: ${({theme}) => theme.colors.textColor};
  transition: color 0.2s;

  ${media.desktop} {
    padding: 0;
  }

  .section-with-image & {
    color: #fff;
  }

  /* .section-title-alignment-centered & {
    text-align: center;
  }

  .section-title-alignment-left & {
    text-align: center;

    ${media.tablet} {
      text-align: left;
    }
  } */

  span {
    display: inline-block;
    &:before {
      content: '';
      display: inline-block;
      width: 100%;
      height: 3px;
      background-color: rgba(120, 194, 87, 0.5);
      margin-bottom: 10px;
    }
  }

  p {
    margin: 0;
  }
`;

export const Title = styled.h2`
  font-size: 24px;
  font-weight: 500;
  letter-spacing: 0.5px;
  line-height: 32px;
  color: ${({theme}) => theme.colors.textColor};
  margin: 0px;
  transition: color 0.2s;

  ${media.tablet} {
    font-size: 32px;
    line-height: 40px;

  }

  ${media.desktop} {
    padding: 0;
  }

  .section-with-image & {
    color: #fff;
  }

  .section-title-alignment-left & {
    /* text-align: center; */
    font-weight: 500;
    font-family: Noto Serif;

    ${media.tablet} {
      text-align: left;
    }
  }

  .section-title-alignment-centered & {
    /* text-align: center; */
  }
`;
